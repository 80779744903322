import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import {
	fluidPadding,
	fluidValues,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getVariantStyles = (tokens, styles) => {
	const sameVariantSections = Object.keys(tokens?.variants).reduce(
		(accumulator, currentValue) => {
			const newClass = `.supt-section--${currentValue} + .supt-section--${currentValue}`;
			return accumulator + (accumulator === '' ? '' : ', ') + newClass;
		},
		''
	);

	const result = cssObj({
		'.supt-section': fluidPadding(styles?.section?.padding),

		/* stylelint-disable-next-line */
		[sameVariantSections]: fluidValues(
			'marginTop',
			styles?.section?.margin?.top?.[0],
			styles?.section?.margin?.top?.[1]
		),
	});

	return result;
};

const getStyles = (tokens, styles) => css(getVariantStyles(tokens, styles));

const getPageEditStyles = (tokens, styles) =>
	cssObj({
		'.post-type-page .edit-post-visual-editor .editor-styles-wrapper .block-editor-block-list__layout':
			getVariantStyles(tokens, styles),
	});

export { getStyles, getPageEditStyles };
